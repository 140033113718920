<template>
  <div class="pt-12">
    <div class="lg:max-w-4xl lg:mx-auto px-6">
      <div class="text-center">
        <p class="text-sm">Your question is</p>
        <h2 class="text-3xl text-primary-orange font-semibold">
          {{topSearch.question}}
        </h2>
      </div>
      <div class="mt-8">
        <p class="text-center text-sm">The answer is</p>
        <div v-if="searching" class="mt-4 space-y-4 text-center">
          <LoadingSpinner />
          <p>Searching...</p>
        </div>
        <div v-else>
          <div v-if="noResult" class="mt-4 space-y-4 text-center">
            <i class="far fa-times-circle text-7xl text-primary-orange"></i>
            <p>No Results Found</p>
            <p>
              Sorry, we commit to continiously improve and update our content.
            </p>
          </div>
          <p v-else class="mt-2 border py-3 px-4 rounded-lg bg-white">
            {{topSearch.answers[0]}}
          </p>
        </div>
      </div>
      <template v-if="!noResult">
        <!-- <div class="lg:flex lg:items-center lg:justify-between lg:mt-6">
          <div class="text-center mt-6 lg:mt-0">
            <Button class="space-x-2">
              <i class="fas fa-volume-up"></i>
              <span class="text-sm">Listen to answer only</span>
            </Button>
          </div>
          <div class="mt-8 lg:mt-0 lg:flex lg:items-center lg:space-x-4">
            <p class="text-center text-sm">Share</p>
            <div class="mt-2 flex justify-center">
              <SocialLinks />
            </div>
          </div>
        </div> -->
        <div v-if="relatedResults!==''" class="mt-12 lg:mt-24">
          <h2
            class="mt-3 text-3xl font-semibold text-primary-orange text-center"
          >
            Other Related Questions:
          </h2>
          <div
            class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"
          ></div>
          <p class="text-center text-sm">Browse other topics to know more.</p>
          <ul class="mt-8 list-disc px-6 space-y-2 underline">
            <li v-for="res in relatedResults" :key="res.id">
              <a href="#" v-on:click.self="resetResults(res.id)">{{res.question}}</a>
            </li>            
          </ul>
        </div>
      </template>
    </div>
    <!-- <div
      class="py-12 mt-12"
      style="
        background: url('/img/tall-bg.svg') no-repeat;
        background-position: contain;
      "
    >
      <div class="px-6 lg:px-0 lg:max-w-2xl lg:mx-auto">
        <h2 class="text-3xl font-semibold text-primary-orange text-center">
          Ask Me a Question
        </h2>
        <div
          class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"
        ></div>
        <p class="text-center text-sm">Discover your rights</p>
        <MultiSearch class="mt-6" />
      </div>
    </div> -->
    <div class="py-12 px-6 md:px-0 md:max-w-2xl md:mx-auto">
      <h2 class="mt-3 text-3xl font-semibold text-primary-orange text-center">
        Still Need Help?
      </h2>      
      <div class="mt-6 md:pt-6 text-center px-6 md:px-12 space-y-6">        
        <Button @click="$router.push('/question')" class="w-full sm:w-auto"
          >Submit a Question</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MultiSearch from "@/components/MultiSearch";
import SocialLinks from "@/components/SocialLinks";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["result"],
  components: {
    Button,
    MultiSearch,
    SocialLinks,
    LoadingSpinner,
  },
  data: () => ({
    topSearch : "",
    relatedResults : ""

  }),
  created() {},
  computed: {
    searching() {
      return this.$store.state.searching;
    },
    results() {
      this.topSearch = this.topSearchResult();
      this.relatedResults = this.relatedSearchResults();
      if(this.topSearch !== ""){
        if(this.topSearch.answers === null || this.topSearch.answers.length === 0){
          this.topSearch.answers = ["This question has not yet been answered"]
        }
      }
      if(this.relatedResults !== ""){
        this.relatedResults.forEach(res => {
          if(res.answers === null || res.answers.length === 0){
          this.relatedResults.answers = ["This question has not yet been answered"]
        }
        });        
      }
      return this.topSearch;
    },
    noResult() {
      return !!!this.results;
    },
  },
  methods: {
    topSearchResult(){
      return this.$store.state.result.length > 0 ? this.$store.state.result[0] : "";
    },
    relatedSearchResults(){
      return this.$store.state.result.length > 1 ? this.$store.state.result.filter((v,i) => i !== 0) : "";
    },
    resetResults(selectedItem){
      var i2 = this.$store.state.result.findIndex(i => i.id === selectedItem);
      this.$store.state.result = this.$store.state.result.slice(0,0).concat(this.$store.state.result[i2],this.$store.state.result.slice(1,i2),this.$store.state.result[0],this.$store.state.result.slice(i2+1));
    }
  },
};
</script>

<style></style>
